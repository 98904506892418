import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { userService } from '../../../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../../static/constants'
import { Button, InputText } from '../../../atoms'
import { Form } from '../../../molecules'

declare var window: IWindow

const FIELDS = {
  email: 'email',
}

interface IState {
  email: string
  errors: any
}

class UserConfirmationsNew extends React.Component<{}, IState> {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
      errors: {},
    }
  }

  public handleUpdateForm = (errors, hasNoError) => {
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    })
  }

  public handleSubmit = async (initialValues, values) => {
    const { flush } = await userService.createConfirmation({ user: values })
    window.flashMessages.addMessage({
      text: flush.message,
      type: flush.type,
    })
    setTimeout(() => {
      window.location.href = '/'
    }, 5000)
  }

  public render() {
    return (
      <S.UserConfirmationsNew>
        <S.Panel>
          <S.Title>
            <p>確認メール再送信</p>
          </S.Title>
          <Form
            fields={FIELDS}
            handleSubmit={this.handleSubmit}
            handleUpdateForm={this.handleUpdateForm}
          >
            <S.FormItem>
              <InputText
                required={true}
                name="email"
                defaultValue=""
                label=""
                placeholder={I18n.t('generic.email')}
                error={this.state.errors.email}
              />
            </S.FormItem>
            <S.Buttons>
              {this.state.errors.login && <span className="error">{this.state.errors.login}</span>}
              <Button primary={true}>{I18n.t('generic.send')}</Button>
              {/* <p>
                <a href="/users/sign_up">{I18n.t('generic.signup')}</a>
              </p> */}
            </S.Buttons>
          </Form>
        </S.Panel>
      </S.UserConfirmationsNew>
    )
  }
}

const S: { [key: string]: AnyStyledComponent } = {}
S.UserConfirmationsNew = styled.main`
  padding-top: 36px;

  .error {
    color: ${COLORS.Danger};
    display: block;
    margin-bottom: 12px;
  }
`
S.Title = styled.div`
  text-align: center;
  margin-bottom: 28px;
`
S.Buttons = styled.div`
  margin-top: 24px;
  text-align: center;

  > p {
    display: block;
    margin: 16px auto 0;

    a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  .Button {
    display: block;
    margin: 0 auto;
    width: 360px;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
  }
`

S.Panel = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  max-width: 600px;
  margin: 0 auto;
  padding: 24px;
`

S.FormItem = styled.div`
  width: 360px;
  margin: 0 auto;

  & + & {
    margin-top: 16px;
  }

  p {
    width: 48px;

    span {
      margin-left: 0px;
    }
  }
`

export default UserConfirmationsNew
