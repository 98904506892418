import axios from 'axios'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { ICartItem, IPost, IUser, IWindow } from '../../../core/interfaces'
import { reservationService } from '../../../core/services'
import { BREAKPOINT_TABLET_MOBILE, DELIVERY_AREA } from '../../../static/constants'
import { setNativeValue } from '../../../utils/form'
import { formatPrice } from '../../../utils/number'
import { Button, CheckBox, InputTextArea, Spinner } from '../../atoms'
import { Form, InputDateTimeOrder } from '../../molecules'
import AddressForm, { ADDRESS_FIELDS, emptyToNull } from '../../organisms/AddressForm'
import DeliveryManagementTable from '../../organisms/DeliveryManagementTable'
import { IReservationParams } from '../posts/show'
const _moment = extendMoment(moment)

interface IProps {
  post: any
  cartItems: ICartItem[]
  reservationParams: IReservationParams
  user: IUser
  bundled_reservation?: any
  initial_delivery_fee?: number
  latest_reservation_id?: number
}
interface IPriceAndAmount {
  amount: number
  price: number
}
declare var window: IWindow

const ReservationNew: React.FC<IProps> = ({
  post,
  user,
  cartItems,
  reservationParams,
  bundled_reservation,
  initial_delivery_fee,
  latest_reservation_id,
}) => {
  const [updatedAddress, setAddress] = React.useState(user.address)
  const [isCompleted, setIsCompleted] = React.useState(false)
  const [showOverlay, setShowOverlay] = React.useState(false)
  const [createdReservation, setCreatedReservation] = React.useState(null)
  const [updatedReservationParams, setReservationParams] = React.useState<IReservationParams>(
    reservationParams
  )
  const [deliveryFee, setDeliveryFee] = React.useState<number | null>(initial_delivery_fee)
  const [deliveryDistance, setDeliveryDistance] = React.useState<number | null>(null)
  const [totalAmount, setTotalAmount] = React.useState<number | null>(null)
  const [priceTotal, setPriceTotal] = React.useState<number | null>(null)
  const [taxTotal, setTaxTotal] = React.useState<number | null>(null)
  const [couponValues, setCouponValues] = React.useState<number[]>([])
  const [storeCouponValue, setStoreCouponValue] = React.useState<number | null>(null)
  const [campaignValue, setCampaignValue] = React.useState<number | null>(null)
  const [campaignName, setCampaignName] = React.useState<string>('')
  const [selectedDeliveryTime, setSelectedDeliveryTime] = React.useState<moment.Moment | null>(null)
  const [priceAndAmount, setPriceAndAmount] = React.useState<IPriceAndAmount>({
    amount: 0,
    price: 0,
  })
  const [blockedDates, setBlockedDates] = React.useState([])
  const [defaultStart, setDefaultStart] = React.useState('')
  const [bundlesReservation, setBundlesReservation] = React.useState(bundled_reservation || null)
  const [isBundled, setIsBundled] = React.useState(false)
  const [bundledFlag, setBundledFlag] = React.useState(false)
  const [deliveryDate, setDeliveryDate] = React.useState(post.delivery_date)
  const [deliveryEndDate, setDeliveryEndDate] = React.useState(
    post.limited_time_flag && post.delivery_end_date
      ? post.delivery_end_date - post.delivery_date
      : 6
  )
  const [deliveryTimeFrame, setDeliveryTimeFrame] = React.useState(post.delivery_time_frame)

  const start = moment()
    .startOf('day')
    .add(deliveryDate, 'days')

  const end = moment(start)
    .endOf('day')
    .add(deliveryEndDate, 'days')

  const range = _moment.range(start, end)
  const startTime = start.format('HH:mm')
  const endTime = end.format('HH:mm')

  const isOutsideRange = day => {
    const response = !range.contains(day)

    let day_name = day.lang('en').format('ddd')
    if (day_name === 'Thu') {
      day_name = 'Thur'
    }
    return response
  }

  React.useEffect(() => {
    const checkBlockDates = async () => {
      const response = await axios.get(`/api/reservations/block_dates?post_id=${post.id}`)
      const blocked = response.data.block_dates.map(date => moment(date))
      setBlockedDates(blocked)
    }
    checkBlockDates()
  }, [])

  const isDayBlocked = day => {
    return blockedDates.some(blockedDay => day.isSame(blockedDay, 'day'))
  }

  const getDeliveryCount = async (date, time) => {
    const response = await axios.get(`/api/reservations/delivery_count?date_time=${date} ${time}`)
    const count = response.data.count
    const deliveryLimit = response.data.delivery_limit
    return { isAvailable: count < deliveryLimit, deliveryLimit }
  }

  const getDefaultStart = async () => {
    const rangeDates = Array.from(_moment.range(start, end).by('days'))
    const timeSlots = ['11:00', '15:00', '19:00']
    let deliveryLimit = 0
    for (const date of rangeDates) {
      if (!blockedDates.some(blockedDate => date.isSame(blockedDate, 'day'))) {
        for (const time of timeSlots) {
          const { isAvailable, deliveryLimit: newLimit } = await getDeliveryCount(
            date.format('YYYY-MM-DD'),
            time
          )
          deliveryLimit = newLimit
          if (isAvailable) {
            return date.format(`YYYY-MM-DD ${time}`)
          }
        }
      }
    }
    return ''
  }

  React.useEffect(() => {
    getDefaultStart().then(result => {
      setDefaultStart(result)
    })
  }, [blockedDates])

  React.useEffect(() => {
    const result = { ...updatedReservationParams }
    result.address = {
      ...result.address,
      ...updatedAddress,
    }
    result.reservation.distance = deliveryDistance || 0
    result.reservation.delivery_fee = deliveryFee

    const deliveryTimeTarget = selectedDeliveryTime || moment()
    result.reservation.specified_delivery_time_at = deliveryTimeTarget.format('YYYY-MM-DD HH:mm:ss')
    setReservationParams(result)
  }, [updatedAddress, deliveryFee, deliveryDistance, selectedDeliveryTime])

  React.useEffect(() => {
    setPriceAndAmount(
      cartItems.reduce(
        (base, item) => {
          ;(base.amount += item.amount), (base.price += calc_price(item))
          return base
        },
        { amount: 0, price: 0 }
      )
    )
  }, [cartItems])

  React.useEffect(() => {
    const fetchDeliveryData = async () => {
      const distance = 100
      const {
        deliveryFee: delivery_fee,
        total_amount,
        price_total,
        tax_total,
        couponValues: coupon_values,
        storeCouponValue: store_coupon_value,
        campaignValue: campaign_value,
        campaignName: campaign_name,
      } = await reservationService.calcDeliveryFeeAndTotal(
        distance,
        deliveryFee,
        reservationParams.reservation.coupons,
        reservationParams.reservation.store_coupon,
        cartItems
      )
      setDeliveryDistance(distance)
      setDeliveryFee(delivery_fee)
      setTotalAmount(total_amount)
      setPriceTotal(price_total)
      setTaxTotal(tax_total)
      setCouponValues(coupon_values)
      setStoreCouponValue(store_coupon_value)
      setCampaignValue(campaign_value)
      setCampaignName(campaign_name)
    }
    fetchDeliveryData()
  }, [])

  const recalculate = async data => {
    try {
      if (data) {
        const selectedTime = data.toDate().getTime()
        const isBundledTime = bundlesReservation.some(reservation => {
          const reservationTime = new Date(reservation.specified_delivery_time_at).getTime()
          return reservationTime === selectedTime
        })
        const {
          total_amount,
          price_total,
          tax_total,
          couponValues: coupon_values,
          storeCouponValue: store_coupon_value,
          campaignValue: campaign_value,
          campaignName: campaign_name,
        } = await reservationService.calcDeliveryFeeAndTotal(
          deliveryDistance,
          isBundledTime && bundledFlag ? 0 : initial_delivery_fee,
          updatedReservationParams.reservation.coupons,
          updatedReservationParams.reservation.store_coupon,
          cartItems
        )
        setDeliveryFee(isBundledTime && bundledFlag ? 0 : initial_delivery_fee)
        setTotalAmount(total_amount)
        setPriceTotal(price_total)
        setTaxTotal(tax_total)
        setCouponValues(coupon_values)
        setStoreCouponValue(store_coupon_value)
        setCampaignValue(campaign_value)
        setCampaignName(campaign_name)
        setIsBundled(isBundledTime && bundledFlag)
      }
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    recalculate(selectedDeliveryTime)
  }, [bundledFlag])

  const calc_price = item => {
    let optionSumItemPrice = 0
    item.cart_options.map(cart_option => {
      cart_option.cart_option_items.map(cart_option_item => {
        optionSumItemPrice += Number(cart_option_item.settlement_price)
      })
    })
    return (item.settlement_price + optionSumItemPrice) * item.amount
  }

  const getSortedDeliveryTimes = reservations => {
    return Array.from(
      new Set(
        reservations.map(reservation => {
          let deliveryTimeRange
          switch (new Date(reservation.specified_delivery_time_at).getHours()) {
            case 11:
              deliveryTimeRange = '11:00-13:00'
              break
            case 15:
              deliveryTimeRange = '15:00-18:00'
              break
            case 19:
              deliveryTimeRange = '19:00-20:00'
              break
            default:
              deliveryTimeRange = ''
          }
          return `${new Date(
            reservation.specified_delivery_time_at
          ).toLocaleDateString()} ${deliveryTimeRange}`
        })
      )
    ).sort((a: string, b: string) => {
      const [dateAString, timeAString] = a.split(' ')
      const [dateBString, timeBString] = b.split(' ')
      const [startHourAString] = timeAString.split('-')[0].split(':')
      const [startHourBString] = timeBString.split('-')[0].split(':')
      const dateA = new Date(dateAString)
      const dateB = new Date(dateBString)
      dateA.setHours(parseInt(startHourAString, 10))
      dateB.setHours(parseInt(startHourBString, 10))
      return dateA.getTime() - dateB.getTime()
    })
  }
  React.useEffect(() => {
    if (!isCompleted) {
      return
    }
    // GA購入イベント
    if (window.dataLayer) {
      const now = new Date()
      const formattedDate =
        now.getFullYear() + ('0' + (now.getMonth() + 1)).slice(-2) + ('0' + now.getDate()).slice(-2)
      const formattedTime =
        ('0' + now.getHours()).slice(-2) +
        ('0' + now.getMinutes()).slice(-2) +
        ('0' + now.getSeconds()).slice(-2)

      const transactionId = `${latest_reservation_id}_${formattedDate}_${formattedTime}`
      const transformedItems = cartItems.map(item => ({
        item_name: item.name || 'Unknown', // 商品名
        item_id: item.id || 'Unknown', // 商品ID
        price: item.settlement_price || 0, // 単価
        quantity: item.amount || 0, // 数量
      }))

      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: transactionId, // 決済ID
          value: totalAmount, // 収益
          shipping: reservationParams.reservation.delivery_fee, // 配送料
          currency: 'JPY', // 通貨
          coupon: reservationParams.reservation.coupons, // 利用したクーポン
        },
        items: transformedItems,
      })
    }
  }, [totalAmount, cartItems, isCompleted, reservationParams])

  return (
    <ReservationNewWrapper>
      <div className="Panel">
        {isCompleted ? (
          <div className="ReservationNew_Completed">
            <h2 className="ReservationNew_CompletedHeader">
              {I18n.t('reservation.reservation_completed')}
            </h2>
            <div className="ReservationNew_CompletedButtons">
              <Button>
                <a href={`/reservations/${createdReservation.slug}`}>
                  {I18n.t('reservation.reservation_detail')}
                </a>
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="Panel_Section">
              <h3>{I18n.t('reservation.reservation_detail')}</h3>
              <table className="ReservationNew_Table">
                <thead>
                  <tr>
                    <th>商品</th>
                    <th>単価(税込)</th>
                    <th>個数</th>
                    <th>小計(税込)</th>
                  </tr>
                </thead>
                {cartItems.map((item, index) => (
                  <tbody key={`${item.id}-${index}`}>
                    <tr>
                      <td data-label="商品名">{item.name}</td>
                      <td data-label="単価(税込)">{formatPrice(item.settlement_price)}</td>
                      <td data-label="個数">{item.amount}</td>
                      <td data-label="小計(税込)">{formatPrice(calc_price(item))}</td>
                    </tr>
                    <>
                      {item.cart_options.map((cart_option, index1) => (
                        <tr className="OptionTr" key={`${cart_option.id}-${index1}`}>
                          <td>
                            <S.OptionItem>
                              {`${cart_option.title}`}
                              {cart_option.cart_option_items.map((cart_option_item, index2) => (
                                <li key={`${cart_option_item.id}-${index2}`}>
                                  {cart_option_item.title}
                                </li>
                              ))}
                            </S.OptionItem>
                          </td>
                          <td>
                            {cart_option.cart_option_items.map((cart_option_item, index2) => (
                              <div key={`${cart_option_item.id}-${index2}`}>
                                {formatPrice(cart_option_item.settlement_price)}
                              </div>
                            ))}
                          </td>
                          <td>
                            {cart_option.cart_option_items.map((cart_option_item, index2) => (
                              <div key={`${cart_option_item.id}-${index2}`}>
                                {cart_option_item.amount}
                              </div>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </>
                  </tbody>
                ))}
                <tbody>
                  <tr>
                    <td />
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      商品代金(税込)
                    </td>
                    <td style={{ textAlign: 'right' }}>{formatPrice(priceTotal + taxTotal)}</td>
                  </tr>
                  <tr>
                    <td />
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      配送料(税込)
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {isNaN(deliveryFee)
                        ? '配送先住所を入力してください'
                        : formatPrice(deliveryFee)}
                    </td>
                  </tr>
                  {campaignValue !== 0 && (
                    <tr>
                      <td />
                      <td colSpan={2} style={{ textAlign: 'right' }}>
                        {campaignName}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        -{''}
                        {isNaN(campaignValue)
                          ? '配送先住所を入力してください'
                          : formatPrice(campaignValue)}
                      </td>
                    </tr>
                  )}
                  {storeCouponValue !== 0 && storeCouponValue !== -1 && (
                    <tr>
                      <td />
                      <td colSpan={2} style={{ textAlign: 'right' }}>
                        店舗クーポン
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        -{''}
                        {isNaN(storeCouponValue)
                          ? '配送先住所を入力してください'
                          : formatPrice(storeCouponValue)}
                      </td>
                    </tr>
                  )}
                  {storeCouponValue === -1 && (
                    <tr>
                      <td />
                      <td colSpan={2} style={{ textAlign: 'right' }}>
                        店舗クーポン
                      </td>
                      <td style={{ textAlign: 'right' }}>このクーポンを使えません</td>
                    </tr>
                  )}
                  {couponValues.some(c => c !== 0) && couponValues.some(c => c !== -1) && (
                    <tr>
                      <td />
                      <td colSpan={2} style={{ textAlign: 'right' }}>
                        クーポン
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        -{''}
                        {couponValues.some(c => isNaN(c))
                          ? '配送先住所を入力してください'
                          : formatPrice(couponValues.reduce((a, x) => a + x, 0))}
                      </td>
                    </tr>
                  )}
                  {couponValues.some(couponValue => couponValue === -1) && (
                    <tr>
                      <td />
                      <td colSpan={2} style={{ textAlign: 'right' }}>
                        クーポン
                      </td>
                      <td style={{ textAlign: 'right' }}>このクーポンを使えません</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td />
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      合計
                    </td>
                    <td className="totalAmount" style={{ textAlign: 'right' }}>
                      {totalAmount >= 0 ? formatPrice(totalAmount) : '計算中です'}
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div className="Panel_Section">
                {bundlesReservation && bundlesReservation.length > 0 && (
                  <>
                    <p className="emphasis">同一配送可能の注文があります。</p>
                    <p className="emphasis">
                      同送希望をチェック後、同一日時、同一配送先を指定していただけると本注文の配送料が無料になります。
                    </p>
                    <p className="emphasis">
                      ※ 配送状況により同一日時を選択できない場合がございます。
                    </p>
                    {getSortedDeliveryTimes(bundlesReservation).map((time, index) => (
                      <div key={index}>{time}</div>
                    ))}
                    <CheckBox
                      label="同送希望"
                      name="bundled_flag"
                      defaultChecked={bundledFlag}
                      onChangeHandler={e => setBundledFlag(e.target.checked)}
                    />
                  </>
                )}
                <InputDateTimeOrder
                  required={true}
                  name="set_delivery_time"
                  label="配送日時"
                  defaultValue={defaultStart}
                  isOutsideRange={isOutsideRange}
                  isDayBlocked={isDayBlocked}
                  deliveryTimeFrame={deliveryTimeFrame}
                  onChangeHandler={async data => {
                    setSelectedDeliveryTime(data)
                    recalculate(data)
                  }}
                />
                <p className="emphasis">
                  ※ 19:00-20:00での配送時間指定は北5条〜南4条エリアのみとなります。
                </p>
              </div>
              {/* 配送管理ここから */}
              <DeliveryManagementTable
                defaultDate={start.format('YYYY-MM-DD 11:00:00')}
                deliveryTimeFrame={deliveryTimeFrame}
                deliveryEndDate={end.format('YYYY-MM-DD 23:59:59')}
              />
              {/* 配送管理ここまで */}
              <div className="Panel_Section">
                <h4>{I18n.t('reservation.remarks_notes')}</h4>
                {post.note && <div className="Post_Note">{post.note}</div>}
                <p>
                  <i className="material-icons">phone</i> {post.address.phone}
                </p>
              </div>
            </div>
            <div className="Panel_Section">
              <ReservationForm
                post={post}
                user={user}
                reservationParams={updatedReservationParams}
                setAddress={setAddress}
                setCreatedReservation={setCreatedReservation}
                setIsCompleted={setIsCompleted}
                setShowOverlay={setShowOverlay}
                cartItems={cartItems}
                deliveryFee={deliveryFee}
                couponValues={couponValues}
                storeCouponValue={storeCouponValue}
                campaignValue={campaignValue}
                campaignName={campaignName}
                totalAmount={totalAmount}
                priceTotal={priceTotal}
                taxTotal={taxTotal}
                bundledFlag={bundledFlag}
                isBundled={isBundled}
              />
            </div>
          </>
        )}
      </div>
      <S.Cover show={showOverlay}>
        {' '}
        <Spinner />{' '}
      </S.Cover>
    </ReservationNewWrapper>
  )
}

interface IReservationFormProps {
  post: IPost
  user: any
  reservationParams: IReservationParams
  cartItems: any
  deliveryFee: any
  couponValues: number[]
  storeCouponValue: any
  campaignValue: any
  campaignName: any
  totalAmount: any
  priceTotal: any
  taxTotal: any
  bundledFlag: any
  isBundled: any
  setAddress(address: any): void
  setIsCompleted(flag: boolean): void
  setShowOverlay(flag: boolean): void
  setCreatedReservation(reservation: any): void
}

const FORM_RESERVATION_FIELDS = {
  remarks: 'remarks',
  bundled_flag: 'bundled_flag',
}

const FIELDS = {
  ...FORM_RESERVATION_FIELDS,
  ...ADDRESS_FIELDS,
}

const ReservationForm: React.FC<IReservationFormProps> = ({
  post,
  user,
  reservationParams,
  setAddress,
  setIsCompleted,
  setShowOverlay,
  setCreatedReservation,
  cartItems,
  deliveryFee,
  couponValues,
  storeCouponValue,
  campaignValue,
  campaignName,
  totalAmount,
  priceTotal,
  taxTotal,
  bundledFlag,
  isBundled,
}) => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})
  const formRef = React.useRef(null)
  const getStripeParamsRef = React.useRef(null)

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled, values) => {
    setAddress(values)
    setErrors(updatedErrors)
    setIsSubmitEnabled(!isProcessing && updatedIsSubmitEnabled)
  }

  const calc_price = item => {
    let optionSumItemPrice = 0
    item.cart_options.map(cart_option => {
      cart_option.cart_option_items.map(cart_option_item => {
        optionSumItemPrice += Number(cart_option_item.settlement_price)
      })
    })
    return (item.settlement_price + optionSumItemPrice) * item.amount
  }

  const resetProcess = () => {
    setShowOverlay(false)
    setIsProcessing(false)
  }

  const handleSubmit = React.useCallback(
    async (initialValues, values) => {
      const PostalCode = values.postal_code1.split('-').join('')
      // 配送範囲制限
      if (!DELIVERY_AREA.includes(PostalCode)) {
        window.flashMessages.addMessage({
          text: '配送範囲外です。',
          type: 'error',
        })
        return
      }

      const params = {
        ...reservationParams,
        reservation: {
          ...reservationParams.reservation,
        },
        address: {
          ...reservationParams.address,
        },
      }

      // 予約関連の値
      Object.keys(FORM_RESERVATION_FIELDS).forEach(key => {
        params.reservation[key] = values[key]
      })

      // 住所関連の値
      Object.keys(ADDRESS_FIELDS).forEach(key => {
        params.address[key] = emptyToNull(key, values[key])
      })

      setIsProcessing(true)
      setShowOverlay(true)

      const createReservationResponse = await reservationService.createReservation(params)
      resetProcess()

      if (createReservationResponse.reservation) {
        // 全てのアイテムを取得
        const allItemsInStorage = JSON.parse(localStorage.getItem('cartItems')) || []
        // 予約した店舗の商品を除いたアイテムのみを取得
        const remainingItems = allItemsInStorage.filter(item => item.post_id !== post.id)
        // 更新したアイテムリストをローカルストレージに再保存
        localStorage.setItem('cartItems', JSON.stringify(remainingItems))
        setCreatedReservation(createReservationResponse.reservation)
        setIsCompleted(true)
      }
    },
    [getStripeParamsRef]
  )

  return (
    <S.ReservationForm>
      <h3>配送先指定</h3>
      <Form
        fields={FIELDS}
        handleSubmit={handleSubmit}
        handleUpdateForm={handleUpdateForm}
        ref={formRef}
      >
        <input name="bundled_flag" defaultValue={bundledFlag} type="hidden" />
        <S.FormItem>
          <AddressForm
            defaultAddress={user.address}
            withMap={true}
            errors={errors}
            isGuest={true}
            isBundled={isBundled}
            updateForm={() => {
              formRef?.current.handleFormChange()
            }}
          />
        </S.FormItem>
        <S.FormItem>
          <InputTextArea
            required={false}
            name="remarks"
            defaultValue=""
            label={I18n.t('generic.note')}
            error={errors.remarks}
          />
        </S.FormItem>
        <S.FormComment>※さび抜きなど、ご注文に関する特記事項をご記入ください</S.FormComment>

        <div className="Panel_Section">
          <h3>{I18n.t('reservation.reservation_detail')}</h3>
          <table className="ReservationNew_Table">
            <thead>
              <tr>
                <th>商品</th>
                <th>単価(税込)</th>
                <th>個数</th>
                <th>小計(税込)</th>
              </tr>
            </thead>
            {cartItems.map((item, index) => (
              <tbody key={`${item.id}-${index}`}>
                <tr>
                  <td>{item.name}</td>
                  <td>{formatPrice(item.settlement_price)}</td>
                  <td>{item.amount}</td>
                  <td>{formatPrice(calc_price(item))}</td>
                </tr>
                <>
                  {item.cart_options.map((cart_option, index1) => (
                    <tr className="OptionTr" key={`${cart_option.id}-${index1}`}>
                      <td>
                        <S.OptionItem>
                          {`${cart_option.title}`}
                          {cart_option.cart_option_items.map((cart_option_item, index2) => (
                            <li key={`${cart_option_item.id}-${index2}`}>
                              {cart_option_item.title}
                            </li>
                          ))}
                        </S.OptionItem>
                      </td>
                      <td>
                        {cart_option.cart_option_items.map((cart_option_item, index2) => (
                          <div key={`${cart_option_item.id}-${index2}`}>
                            {formatPrice(cart_option_item.settlement_price)}
                          </div>
                        ))}
                      </td>
                      <td>
                        {cart_option.cart_option_items.map((cart_option_item, index2) => (
                          <div key={`${cart_option_item.id}-${index2}`}>
                            {cart_option_item.amount}
                          </div>
                        ))}
                      </td>
                    </tr>
                  ))}
                </>
              </tbody>
            ))}
            <tbody>
              <tr>
                <td />
                <td colSpan={2} style={{ textAlign: 'right' }}>
                  商品代金(税込)
                </td>
                <td style={{ textAlign: 'right' }}>{formatPrice(priceTotal + taxTotal)}</td>
              </tr>
              <tr>
                <td />
                <td colSpan={2} style={{ textAlign: 'right' }}>
                  配送料(税込)
                </td>
                <td style={{ textAlign: 'right' }}>
                  {isNaN(deliveryFee) ? '配送先住所を入力してください' : formatPrice(deliveryFee)}
                </td>
              </tr>
              {campaignValue !== 0 && (
                <tr>
                  <td />
                  <td colSpan={2} style={{ textAlign: 'right' }}>
                    {campaignName}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    -{''}
                    {isNaN(campaignValue)
                      ? '配送先住所を入力してください'
                      : formatPrice(campaignValue)}
                  </td>
                </tr>
              )}
              {storeCouponValue !== 0 && storeCouponValue !== -1 && (
                <tr>
                  <td />
                  <td colSpan={2} style={{ textAlign: 'right' }}>
                    店舗クーポン
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    -{''}
                    {isNaN(storeCouponValue)
                      ? '配送先住所を入力してください'
                      : formatPrice(storeCouponValue)}
                  </td>
                </tr>
              )}
              {storeCouponValue === -1 && (
                <tr>
                  <td />
                  <td colSpan={2} style={{ textAlign: 'right' }}>
                    店舗クーポン
                  </td>
                  <td style={{ textAlign: 'right' }}>このクーポンを使えません</td>
                </tr>
              )}
              {couponValues.some(c => c !== 0) && couponValues.some(c => c !== -1) && (
                <tr>
                  <td />
                  <td colSpan={2} style={{ textAlign: 'right' }}>
                    クーポン
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    -{''}
                    {couponValues.some(c => isNaN(c))
                      ? '配送先住所を入力してください'
                      : formatPrice(couponValues.reduce((a, x) => a + x, 0))}
                  </td>
                </tr>
              )}
              {couponValues.some(couponValue => couponValue === -1) && (
                <tr>
                  <td />
                  <td colSpan={2} style={{ textAlign: 'right' }}>
                    クーポン
                  </td>
                  <td style={{ textAlign: 'right' }}>このクーポンを使えません</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td colSpan={2} style={{ textAlign: 'right' }}>
                  合計
                </td>
                <td className="totalAmount" style={{ textAlign: 'right' }}>
                  {totalAmount >= 0 ? formatPrice(totalAmount) : '計算中です'}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <Button
          handleClick={() => {
            location.href = `/posts/${post.id}`
            return
          }}
          disabled={false}
          primary={false}
        >
          キャンセル
        </Button>
        <Button disabled={!isSubmitEnabled || isProcessing} primary={true}>
          {I18n.t('generic.reservation')}
        </Button>
      </Form>
    </S.ReservationForm>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.ReservationForm = styled.div``
S.FormItem = styled.div<{ hidden?: true }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 16px;
  }
`

S.FormComment = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
  text-align: right;
  color: #777;
`
S.NoImage = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`
const ReservationNewWrapper = styled.div`
  padding: 24px 12px;

  .Panel {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    max-width: 880px;
    margin: 0 auto;
    padding: 24px;
  }

  .Panel_Section {
    margin-top: 24px;
    padding-top: 16px;
    border-top: solid 1px #eaedef;

    > h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }

    .emphasis {
      color: #f66;
      font-weight: bold;
    }
  }

  .ReservationNew_CompletedHeader {
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
  }

  .ReservationNew_CompletedButtons {
    display: flex;
    justify-content: center;
  }

  .ReservationNew_Table {
    width: 100%;
    font-size: 15px;
    tr {
      height: 40px;
      th:nth-child(1) {
        width: 50%;
      }
      th:nth-child(2) {
        text-align: center;
      }
      th:nth-child(3) {
        text-align: center;
      }
      th:nth-child(4) {
        text-align: right;
      }
      td {
        min-width: 60px;
      }
      td:nth-child(1) {
        width: 50%;
      }
      td:nth-child(2) {
        text-align: center;
      }
      td:nth-child(3) {
        text-align: center;
      }
      td:nth-child(4) {
        text-align: right;
      }
    }
    tbody {
      border-bottom: 1px solid #eaedef;
    }
    .OptionTr {
      td {
        font-size: 12px;
      }
      td:nth-child(1) {
        padding-left: 16px;
        @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
          padding-left: 0;
        }
      }
    }
    .totalAmount {
      font-weight: bold;
    }
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      thead {
        display: none;
      }
    }
  }

  .ReservationNew_Post {
    display: flex;
    align-items: center;
  }

  .ReservationNew_Image {
    width: 200px;
    height: 160px;
    border-radius: 3px;
    overflow: hidden;

    > img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
  }

  .ReservationNew_PostInfo {
    flex: 1;
    margin-left: 16px;
  }

  .ReservationNew_PostTitle {
    font-size: 20px;
  }

  .Button {
    margin-top: 24px;
  }

  .Post_Note {
    white-space: pre-wrap;
    overflow: auto;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      max-height: 10em;
    }
  }
`
S.Cover = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.4);

  .Spinner {
    z-index: 20000;
  }
`

S.OptionItem = styled.ul`
  font-size: 12px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 10px;
  }
  display: flex;
  list-style: none;
  li::after {
    content: '、';
  }
  li:first-child::before {
    content: '（';
  }
  li:last-child::after {
    content: '）';
  }
`
export default ReservationNew
