import axios from 'axios'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../static/constants'
import Spinner from '../atoms/Spinner'

interface IProps {
  defaultDate: any
  deliveryEndDate?: any
  deliveryTimeFrame?: string[]
}

const DeliveryManagementTable: React.FC<IProps> = props => {
  const defaultTimes = ['11:00', '15:00', '19:00']
  const defaultDisplayTimes = ['11:00-13:00', '15:00-18:00', '19:00-20:00']

  const times =
    props.deliveryTimeFrame && props.deliveryTimeFrame.length > 0
      ? defaultTimes.filter(time => props.deliveryTimeFrame.includes(time))
      : defaultTimes
  const displayTimes = times.map(time => {
    const index = defaultTimes.indexOf(time)
    return defaultDisplayTimes[index]
  })
  const date = new Date(props.defaultDate)
  const deliveryEndDate = props.deliveryEndDate
    ? new Date(props.deliveryEndDate)
    : new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000)
  const dayNames = ['日', '月', '火', '水', '木', '金', '土']
  const [deliveryData, setDeliveryData] = React.useState(
    Array(times.length)
      .fill(null)
      .map(() => [])
  )
  const [isLoading, setIsLoading] = React.useState(false)
  const [isAllClosed, setIsAllClosed] = React.useState(false)

  const dates = []
  for (let i = 0; i <= (deliveryEndDate.getTime() - date.getTime()) / (24 * 60 * 60 * 1000); i++) {
    const newDate = new Date(date.getTime() + i * 24 * 60 * 60 * 1000)
    const dateString = `${newDate.getMonth() + 1}/${newDate.getDate()}`
    const actualDateString = `${newDate.getFullYear()}-${newDate.getMonth() +
      1}-${newDate.getDate()}`
    const dayOfWeek = dayNames[newDate.getDay()]
    dates.push({ displayDate: `${dateString} (${dayOfWeek})`, actualDate: actualDateString })
  }

  const getDeliveryDataForTime = async (actualDate, time, dayOfWeek) => {
    try {
      // 19:00枠は火曜（2）と日曜（0）以外はクローズ
      console.log(dayOfWeek)
      if (time === '19:00' && dayOfWeek !== 0 && dayOfWeek !== 2) {
        return { status: 'closed', jsx: <S.StatusClosed>✕</S.StatusClosed> }
      }
      const response = await axios.get(
        `/api/reservations/delivery_count?date_time=${actualDate} ${time}`
      )
      const { status } = response.data
      let jsx
      switch (status) {
        case 'closed':
          jsx = <S.StatusClosed>✕</S.StatusClosed>
          break
        case 'busy':
          jsx = <S.StatusBusy>△</S.StatusBusy>
          break
        default:
          jsx = <S.StatusOpen>◯</S.StatusOpen>
          break
      }
      return { status, jsx }
    } catch (error) {
      console.error('Failed to get data:', error)
      return { status: 'Error', jsx: 'Error' }
    }
  }
  React.useEffect(() => {
    const fetchDeliveryData = async () => {
      setIsLoading(true)
      const newDeliveryData = [...deliveryData]
      let closedCounter = 0
      for (let timeIndex = 0; timeIndex < times.length; timeIndex++) {
        for (let dateIndex = 0; dateIndex < dates.length; dateIndex++) {
          const currentDate = dates[dateIndex]
          const dayOfWeek = new Date(currentDate.actualDate).getDay()
          const data = await getDeliveryDataForTime(
            currentDate.actualDate,
            times[timeIndex],
            dayOfWeek
          )
          newDeliveryData[timeIndex][dateIndex] = data.jsx
          if (data.status === 'closed') {
            closedCounter++
          }
        }
      }
      setDeliveryData(newDeliveryData)
      setIsLoading(false)
      if (closedCounter === times.length * dates.length) {
        setIsAllClosed(true)
      }
    }
    fetchDeliveryData()
  }, [])

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isAllClosed && (
            <S.ErrorMessage>
              現在、全ての配送枠が上限に達しております。大変お手数ですが、翌日以降にご注文ください。
            </S.ErrorMessage>
          )}
          <S.DeliveryManagementTable>
            <thead>
              <tr>
                <th>お届け時間</th>
                {displayTimes.map((time, index) => (
                  <th key={index}>{time}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dates.map((currentDate, dateIndex) => (
                <tr key={dateIndex}>
                  <td>{currentDate.displayDate}</td>
                  {deliveryData.map((timeData, timeIndex) => (
                    <td key={timeIndex}>{timeData[dateIndex]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </S.DeliveryManagementTable>
        </>
      )}
    </>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.DeliveryManagementTable = styled.table`
  width: 100%;
  font-size: 15px;
  margin: 16px 0 32px 0;

  tr {
    border: 1px solid ${COLORS.Border};
    height: 40px;
  }

  th,
  td {
    border-right: 1px solid ${COLORS.Border};
    width: 12.5%;
    text-align: center;
    color: ${COLORS.Text};
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    th,
    td {
      font-size: 12px;
    }
  }
`

S.StatusOpen = styled.span`
  color: var(${THEME_COLOR_VARIABLE_NAME});
  font-weight: bold;
`

S.StatusBusy = styled.span`
  color: #e58923;
  font-weight: bold;
`

S.StatusClosed = styled.span`
  color: gray;
  font-weight: bold;
`

S.ErrorMessage = styled.div`
  color: ${COLORS.Danger};
  font-weight: bold;
  font-size: 16px;
`
export default DeliveryManagementTable
